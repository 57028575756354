<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Taman </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputTaman">
            <CRow>
              
              <CCol sm="12">
                <CInput
                  v-model="name"
                  label="Nama Taman"
                  placeholder="Input Nama Taman"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="address"
                  label="Alamat Taman"
                  placeholder="Input Alamat Taman"
                />  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Kecamatan</label>
                <select v-model="kecamatan" placeholder="Pilih Kecamatan" class="form-control">
                  <option value="" disabled selected>Pilih Kecamatan</option>
                  <option value="Pontianak Kota">Pontianak Kota</option>
                  <option value="Pontianak Timur">Pontianak Timur</option>
                  <option value="Pontianak Barat">Pontianak Barat</option>
                  <option value="Pontianak Utara">Pontianak Utara</option>
                  <option value="Pontianak Selatan">Pontianak Selatan</option>
                  <option value="Pontianak Tenggara">Pontianak Tenggara</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="luas"
                  label="Luas (M2)"
                  placeholder="Input Luas Taman"
                />
              </CCol>
              <!-- <CCol sm="6">
                <CInput
                  v-model="persentase_luas"
                  label="Persentase Luas"
                  placeholder="Input Persentase Luas"
                />
              </CCol> -->
              <CCol sm="6">
              <CInput
                v-model="lng"
                label="Longitude"
                placeholder="Input Longitude"
              />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="lat"
                  label="Lattitude"
                  placeholder="Input Lattitude"
                />  
              </CCol> 
              <!-- <CCol sm="12">
                <CInput
                  v-model="lokasi"
                  label="Lokasi Taman"
                  placeholder="Lokasi Taman"
                  readonly="true"
                  @click="openMapModal"
                />  
                <input type="hidden" v-model="lng" /> 
                <input type="hidden" v-model="lat" /> 
              </CCol> -->
              
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Taman </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>

            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/taman">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
      
    >
      <b>Error</b>! Data Taman gagal diinput.
    </CModal>

    <CModal
      title="Pilih Lokasi Taman"
      :show.sync="mapModal"
      size="lg"
      @ok="confirmLocation"
    >
      <div id="map" style="height: 400px; width: 100%;"></div>
      <template #footer>
        <CButton @click="mapModal = false" color="danger">Batal</CButton>
        <CButton @click="confirmLocation" color="success">Simpan</CButton>
      </template>
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      mapModal: false,  // Modal state
      map: null,  // Google Map instance
      marker: null,  // Fixed marker instance
      center: { lat: 0.026330, lng: 109.342503 },
      luas : "",
      name : "",
      file_text: "",
      file: "",
      placeholder: "",
      persentase_luas : "",
      address : "",
      kecamatan : "",
      lng : "",
      lat : "",
      selected : [], // Must be an array reference!
      show: true,
      modalError: false,
    }
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_text = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    openMapModal() {
      this.mapModal = true;
      this.$nextTick(() => {
        this.initializeMap();
      });
    },
    // Initialize Google Map
    initializeMap() {
      if (this.map) return;  // Prevent re-initialization

      const mapOptions = {
        center: this.center,
        zoom: 13,
      };

      this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

      // Fixed marker in the center
      this.marker = new google.maps.Marker({
        position: this.map.getCenter(),
        map: this.map,
        draggable: false,
      });

      // Update coordinates when the map is moved
      this.map.addListener("center_changed", () => {
        const center = this.map.getCenter();
        this.lat = center.lat().toFixed(6);
        this.lng = center.lng().toFixed(6);
        this.marker.setPosition(center);
      });
    },
    // Confirm location and close modal
    confirmLocation() {
      this.mapModal = false;
      this.lokasi = this.lat+", "+this.lng
    },
    
    inputTaman: function(event){
      // Simple POST request with a JSON body using axios
      // const taman = { 
      //                     name:this.name,
      //                     address:this.address,
      //                     kecamatan:this.kecamatan,
      //                     luas: this.luas, 
      //                     persentase_luas: this.persentase_luas, 
      //                     lng: this.lng, 
      //                     lat: this.lat
      //                     };

      const formData = new FormData()
      
      // formData.append('id_informasi_pohon', this.$route.params.id_informasi_pohon)
      formData.append('name', this.name)
      formData.append('address', this.address)
      formData.append('kecamatan', this.kecamatan)
      formData.append('luas', this.luas)
      formData.append('lng', this.lng)
      formData.append('lat', this.lat)

      if (this.file_text) {
          // alert(this.foto_informasi_pohon);
        formData.append('file', this.file, this.file.name)      
        formData.append('file_text', this.file.name)
      }
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"taman", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.$router.push('/taman');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>